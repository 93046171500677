import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TokenService } from './token.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private _tokenService: TokenService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this._tokenService.token;
    let params = {};
    if (token) {
      params = {
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      };
    }
    const clonedRequest = req.clone(params);
    return next.handle(clonedRequest);
  }
}
