export class EndpointsConstants {
    public static readonly FEEDBACK:   string = './assets/mocks/feedback.json';
    public static readonly FEEDBACKS:  string = './assets/mocks/feedbacks.json';
    public static readonly GLOBAL_NPS: string = './assets/mocks/global.nps.json';
    public static readonly LOG_IN:     string = './assets/mocks/token.json';
    public static readonly PROFILE:    string = './assets/mocks/profile.json';
    public static readonly PROJECT:    string = './assets/mocks/project.json';
    public static readonly PROJECTS:   string = './assets/mocks/projects.json';
    public static readonly REGISTER:   string = './assets/mocks/token.json';
    public static readonly TIMELINE:   string = './assets/mocks/timeline.json';
    public static readonly USERS:      string = './assets/mocks/users.json';
}
