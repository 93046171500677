import { HttpParams } from '@angular/common/http';

export function setHttpParams(reqOptions: any): HttpParams {
    let params = new HttpParams();
    if (!reqOptions) {
        return params;
    }

    if (reqOptions.key && reqOptions.value) {
        return params.set(reqOptions.key, reqOptions.value);
    }

    for (const key of Object.keys(reqOptions)) {
        params = params.set(key, reqOptions[key]);
    }
    return params;
}
