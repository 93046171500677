import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EndpointsConstants } from '../../core/constants';
import { Profile } from '../interfaces';
import { ServerErrorHelper } from 'src/app/core/utils';
import { environment } from '../../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient) {}

  public profile(): Observable<Profile> {
    console.log('ProfileService profile API');
    return this._http
      // .get<any>(`${environment.API_HTTP_URL}${EndpointsConstants.PROFILE}`)
      .get<any>(`${EndpointsConstants.PROFILE}`)
      .pipe(
        catchError(error => {
          const messageError = new ServerErrorHelper(error).message;
          return throwError(messageError);
        })
      );
  }
}
