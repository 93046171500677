import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

import { TokenService } from '../services/token.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlyLoggedInGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private _tokenService: TokenService
  ) { }

  private _isLogged(): boolean {
    const isLoggedIn = this._tokenService.isLoggedIn();
    if (isLoggedIn) {
      return true;
    } else {
      console.warn(`You don't have permission to view this page.`, `Warning!`);
      this.router.navigate(['authorization']);
      return false;
    }
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._isLogged();
  }

  public canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this._isLogged();
  }
}
