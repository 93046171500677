import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private _refreshToken: string;

  constructor(
    private _jwtHelper: JwtHelperService,
    private _localStorageService: LocalStorageService
  ) { }

  public clearToken(): void {
    this._localStorageService.remove('token');
    // Clear temp data
    this._localStorageService.remove('is_expiration');
    this._localStorageService.remove('is_frozen');
  }

  public setTokens(value: any): void {
    this._localStorageService.set('token', value.access_token);
    this._refreshToken = value.refresh_token;
  }

  public get token(): string {
    return this._localStorageService.get('token');
  }

  public get refreshToken(): string {
    return this._refreshToken;
  }

  public isLoggedIn(): boolean {
    return this.token ? !this._jwtHelper.isTokenExpired(this.token) : false;
  }

}
