import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { RoleService } from '../../shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private _roleService: RoleService, public router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const expectedRole = route.data.expectedRole;

    return this._roleService.myRole().pipe(
      map(role => {
        if (role && role !== expectedRole) {
          this.router.navigate(['/']);
          console.log('No tienes permisos para acceder a esta ruta.');
          return false;
        }
        return true;
      })
    );
  }
}
