import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class OnlyUnauthorizedGuard implements CanActivate {

  constructor(
    private _tokenService: TokenService,
    private _router:       Router
  ) { }


  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const isLoggedIn = this._tokenService.isLoggedIn();
      if (!isLoggedIn) {
        return true;
      } else {
        console.warn(`This page only for Unauthorized users.`, `Warning!`);
        this._router.navigate(['/dashboard']);
        return false;
      }
  }
}
