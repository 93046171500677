import { HttpErrorResponse } from '@angular/common/http';

export class ServerErrorHelper {

    private _message: string;
    private _errors:  string;

    constructor(
        private _error: HttpErrorResponse
    ) {
        if (_error) {
            this._message = _error.error.message;
            this._errors  = _error.error.errors ? JSON.stringify(_error.error.errors).replace(/\"|{|}/g, '') : '';
        } else if (_error) {
            this._message = _error.toString();
        } else {
            this._message = 'Server error';
        }
    }

    public get message(): string {
        const details = `${this._errors.replace(/\[/g, '(').replace(/\]/g, ')').replace(/_/g, ' ')}`;
        return details !== '' ? `Error ${details}` : this._message;
    }
}

