import { Injectable } from '@angular/core';

interface ErrorLog {
  message:  string;
  url:      string;
  stack:    string;
  errors:   any;
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  public log(error: ErrorLog): void {
    console.error('REHEGOO ERROR LOG: ', error);
    // @TODO: Send error log on the server
  }
}
