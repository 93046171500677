import { Component, OnInit } from '@angular/core';
import { SessionCheckService } from './core/services/session-check.service';
import { RefreshTokenService } from './core/services/refresh-token.service';
import { TokenService } from './core/services';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NetPromoterScore';

  constructor(
    private _sessionCheckService: SessionCheckService,
    private _refreshTokenService: RefreshTokenService,
    private _tokenService: TokenService
  ) { }

  public ngOnInit() {
    this._sessionCheckService.isTokenExpired()
      .pipe(
        distinctUntilChanged(),
        mergeMap(isExpToken => this._refreshTokenService.refreshToken(isExpToken)))
      .subscribe((res) => {
        if (res) {
          this._tokenService.setTokens(res.data.token);
        }
      }, error => this._tokenService.clearToken());

  }
}
