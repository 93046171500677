import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';

import { ForceExitAccountService } from './force-exit-account.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private _injector:                Injector,
    private _locationStrategy:        LocationStrategy
  ) { }

  public handleError(error) {

    const loggingService          = this._injector.get(LoggingService),
          forceExitAccountService = this._injector.get(ForceExitAccountService),
          url                     = this._locationStrategy instanceof PathLocationStrategy
                                      ? this._locationStrategy.path()
                                      : '';
    let message, errors;

    if (error) {
      message = error.message || error.toString();
      errors  = error.errors  || null;
    } else {
      message = 'Server error';
      errors  = error;
    }

    // get the stack trace, lets grab the last 10 stacks only
    StackTrace.fromError(error).then(stackframes => {
      const stackString = stackframes
        .splice(0, 20)
        .map(sf => sf.toString()).join('\n');
      // log on the server
      loggingService.log({ message, url, stack: stackString, errors });
    }).catch(e => loggingService.log({ message, url, stack: '', errors }));

    if (error === 'Unauthorized') {
      forceExitAccountService.exit();
    }

    throw error;
  }

}
