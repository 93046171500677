import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class ForceExitAccountService {

  constructor(
    private _router:       Router,
    private _tokenService: TokenService,
  ) { }

  public exit() {
    this._tokenService.clearToken();
    this._router.navigate(['authorization']);
  }

}

