import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';

import { TokenService } from './token.service';
import { ForceExitAccountService } from './force-exit-account.service';

@Injectable({
  providedIn: 'root'
})
export class SessionCheckService {

  constructor(
    private _jwtHelper:               JwtHelperService,
    private _tokenService:            TokenService,
    private _forceExitAccountService: ForceExitAccountService
  ) { }

  public isTokenExpired(): Observable<boolean> {
    return interval(10000).pipe(map(() => {
      const token = this._tokenService.token;
      if (token) {
        if (this._jwtHelper.isTokenExpired(token)) {
          this._forceExitAccountService.exit();
          return false;
        } else {
          const isExpiredDate = new Date(this._jwtHelper.getTokenExpirationDate(token));
          isExpiredDate.setMinutes(isExpiredDate.getMinutes() - 5);
          return isExpiredDate.getTime() <= Date.now();
        }
      }
    }));
  }
}
