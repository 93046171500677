import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  OnlyLoggedInGuard,
  OnlyUnauthorizedGuard,
  RoleGuard
} from './core/guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/authorization/log-in',
    pathMatch: 'full'
  },
  {
    path: 'authorization',
    loadChildren: () =>
      import('./authorization/authorization.module').then(
        m => m.AuthorizationModule
      ),
    canActivate: [OnlyUnauthorizedGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [OnlyLoggedInGuard]
  },
  {
    path: 'nps-feedback',
    loadChildren: () =>
      import('./nps-feedback/nps-feedback.module').then(
        m => m.NpsFeedbackModule
      ),
    canActivate: []
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [OnlyLoggedInGuard, RoleGuard],
    data: { expectedRole: 'admin' }
  },
  {
    path: '**',
    redirectTo: '/authorization/log-in',
    canActivate: [OnlyUnauthorizedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
